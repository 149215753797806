<template>
  <div class="logincbox">
    <div class="title ta-c">欢迎登录章易签</div>
    <choose class="mb-40" @choose="chooseChange" :operationIndex="state.operationIndex"></choose>
    <el-form :model="formModel" class="demo_ruleForm" ref="ruleFormRef">
      <el-form-item :rules="[{ validator: validPhoneData, trigger: 'blur' }]" prop="phone">
        <el-input v-model="formModel.phone" placeholder="手机号" />
      </el-form-item>
      <template v-if="state.operationIndex == 0">
        <el-form-item :rules="[{ validator: validPasswordData, trigger: 'blur' }]" prop="password">
          <el-input v-model="formModel.password" placeholder="请输入密码" show-password />
        </el-form-item>
      </template>
      <template v-if="state.operationIndex == 1">
        <el-form-item
          :rules="[{ required: true, message: '请输入验证码' }, { min: 6, max: 6, message: '验证码的长度为6', trigger: 'blur' }]"
          prop="code" class="pt-r">
          <verificationCode ref="verificationCodeRef" class="pt-a vcode" :CSStype="1" @Emit="vCodeEmit">
          </verificationCode>
          <el-input v-model="formModel.code" placeholder="请输入验证码" maxlength="6" />
        </el-form-item>
      </template>
    </el-form>
    <read ref="readRef"></read>
    <oabutton class="mt-8 mb-30" width='462' height='48' title="登录" CSStype=2
      style="border-radius: 4px;font-size: 18px;font-weight: 500;" @buttonclick="login()" v-model:loading="state.loading">
    </oabutton>
    <div class="dp-f register jc-c">
      <div class="cu-p" @click="router_push_name('register')">注册账号</div>
      <div class="mr-6 ml-6">｜</div>
      <div class="cu-p" @click="emit('setKey', 'password')">找回密码</div>
    </div>
  </div>
</template>
<script setup>
import { reactive, ref, unref, defineEmits, getCurrentInstance, nextTick, onMounted } from 'vue'
import { loginByUsername, loginByCode } from "@/api/login";
import { useStore } from "vuex";
import { useRoute } from 'vue-router';
import { validPhone, validPassword } from "@/utils/server/validate.js";
import { router_push_name } from "@/utils/server/router.js";
import { handleMessage,handleCofirm } from "@/utils/server/confirm.js";
import { encryption } from '@/utils/encryption/encryption.js'
import { getbutpermission } from "@/utils/base/permission.js"
import { httpToken } from "@/utils/request";
import { setTheme } from "@/utils/base/theme.js"
import { setwatermark } from "@/utils/server/setwatermark.js"
import qs from "qs";
import verificationCode from '@/components/button/verificationCode.vue'//验证码组件
import choose from './choose.vue'//选择组件
import read from './read.vue'//阅读协议
const route=useRoute()
const instance = getCurrentInstance();//强制刷新
const store = useStore();
const state = reactive({
  operationIndex: 0,//当前操作下标
  loading: false,//loading显示
  checkPhone: 0,//手机号是否重复 1:手机号可用 2:手机号重复
})
// 增加调取隐私协议接口 然后判断协议是否更新
// handleCofirm('《隐私协议》已更新')
const ruleFormRef = ref()//表单ref
const readRef = ref()//阅读协议ref
const verificationCodeRef = ref()//验证码组件ref
const emit = defineEmits(['setKey'])
// 登录
const login = (() => {
  console.log('unref(readRef)', unref(readRef))
  // 是否阅读协议
  if (unref(readRef).state.isread == true) {
    submitForm()
  } else {
    unref(readRef).jitter()
    handleMessage('请先阅读并同意《使用协议》和《隐私协议》')
  }
})
// 表单提交
const submitForm = async () => {
  state.loading = true
  const form = unref(ruleFormRef)
  if (!form) {
    state.loading = false
    return
  }
  await form.validate((valid) => {
    // console.log('valid',valid)
    if (valid) {
      // 密码登录
      if (state.operationIndex == 0) {
        passwordLogin()
      } else if (state.operationIndex == 1) {//验证码登录
        codeLogin()
      }
    } else {
      state.loading = false
    }
  })
}
// 密码登录
const passwordLogin = async () => {
  const user = encryption({
    data: { password: formModel.password },
    key: 'thanks,pig4cloud',
    param: ['password']
  })
  await loginByUsername(formModel.phone, user.password).then(res => {
    window.sessionStorage.setItem("token", res.access_token);//权限的token
    window.sessionStorage.setItem("user", JSON.stringify(res.data));//用户信息
    if (res.data.user_info.sysCompany && res.data.user_info.sysCompany.certStatus > 1) {
      window.sessionStorage.setItem("certstatus", res.data.user_info.sysCompany.certStatus) 
    } else {
      window.sessionStorage.setItem("certstatus", 1) 
    }
    store.state.contractId=window.location.href.split('contractId=')[1]
    // 登录成功后请求动态路由 代增加
    store.dispatch("FETCH_PERMISSION",'login').then(() => {
      if (res.data.user_info.sysCompany && res.data.user_info.sysCompany.signInformationDefinition && res.data.user_info.sysCompany.signInformationDefinition.welcomeMessage) {
        handleMessage(res.data.user_info.sysCompany.signInformationDefinition.welcomeMessage, 'success')
      }
    });
    // 获取按钮权限 可传入菜单id 继续子权限的查询
    getbutpermission()
    setTheme()
    // setwatermark()
    state.loading = false
  }).catch(res => {
    state.loading = false
  })
};
// console.log('window.location.href',window.location.href.split('contractId=')[1])
// 验证码登录
const codeLogin = async () => {
  await loginByCode(formModel.phone, formModel.code).then(res => {
    window.sessionStorage.setItem("token", res.access_token);//权限的token
    window.sessionStorage.setItem("user", JSON.stringify(res.data));//用户信息
    if (res.data.user_info.sysCompany && res.data.user_info.sysCompany.certStatus > 1) {
      window.sessionStorage.setItem("certstatus", res.data.user_info.sysCompany.certStatus) 
    } else {
      window.sessionStorage.setItem("certstatus", 1) 
    }
    store.state.contractId=window.location.href.split('contractId=')[1]
    // 登录成功后请求动态路由 代增加
    store.dispatch("FETCH_PERMISSION",'login').then(() => {
      if (res.data.user_info.sysCompany && res.data.user_info.sysCompany.signInformationDefinition && res.data.user_info.sysCompany.signInformationDefinition.welcomeMessage) {
        handleMessage(res.data.user_info.sysCompany.signInformationDefinition.welcomeMessage, 'success')
      }
    });
    // 获取按钮权限 可传入菜单id 继续子权限的查询
    getbutpermission()
    setTheme()
    // setwatermark()
    state.loading = false
  }).catch(res => {
    state.loading = false
  })
}
// 验证码返回
const vCodeEmit = (() => {
  // 手机号可用情况发起验证码
  if (state.checkPhone == 1) {
    httpToken({
      method: "post",
      url: '/admin/app/sms',
      data: {
        'phone': formModel.phone,
        'exist': true,
        'appid': '1',
        'type': 1,
      }
    }).then(res => {
      handleMessage("验证码发送成功", "success")
      unref(verificationCodeRef).send()
    })
  } else {
    handleMessage('手机号未注册')
  }
})
// 校验密码格式
const validPasswordData = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入密码'))
  } else {
    if (validPassword(value)) {
      callback()
    } else
      callback(new Error('密码格式错误(8-16位数字,字母和特殊字符任意两种组合密码)'))
  }
}
// 校验手机号格式
const validPhoneData = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入手机号'))
  } else {
    if (validPhone(value)) {
      httpToken({
        method: "post",
        url: '/admin/app/checkPhone',
        data: qs.stringify({
          'phone': value,
          'type': 1
        })
      }).then(res => {
        state.checkPhone = res.data
        // res.data 1手机号已存在 2手机号不存在
        if (res.data == 1) {
          callback()
        } else {
          callback(new Error(res.msg))
        }
      }).catch(() => {
        callback(new Error('校验手机号接口请求失败'))
      })
    } else
      callback(new Error('手机号格式错误'))
  }
}
const formModel = reactive({
  password: '',//密码
  phone: '',//手机号
  code: '',//验证码
})

const chooseChange = (ind) => {
  state.operationIndex = ind
  nextTick(() => {
    unref(ruleFormRef).clearValidate()//清除表单验证结果
  })
}

onMounted(() => {
  readRef.value.setEnableClick()
})
</script>
<style lang="scss" scoped >
.logincbox {
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333330;

  .title {
    margin-bottom: 74px;
  }

  .demo_ruleForm {
    ::v-deep .el-input {
      width: 460px;
      height: 54px;
      border: 1px solid #E8E8E8;

      .el-input__wrapper {
        box-shadow: none;
      }
    }
  }

  .register {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #EEC774;
  }
}

::v-deep .el-input__wrapper {
  padding: 1px 15px;
}

.vcode {
  z-index: 10;
  right: 18px;
}
</style>